import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import factorySingleton from '../../services/factory'


const current = "trophy"
const notCurrent = "home"

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const [info, setInfo] = useState()
  const [loading, setLoading] = useState(true)

  const tokensService = factorySingleton.tokensService;

  useEffect(() => {
    const fetch = async () => {
      const info = await tokensService.getUserInfo()
      setInfo(info)
      setLoading(false)
    }
    fetch()
  }, [])

  const onSubcriptions = useCallback(() => {
    navigate('/subscriptions')
  })

  const onExit = useCallback(() => {
    tokensService.remove()
    navigate('/sign-in')
  })

  if (loading === true)
    return (<></>);

  return (
    <header >
      <div className="header_container">
        <div className="logo-burger">
        <div className="logo"><span style={{marginRight:"-7px"}}><img src="./images/polyglotio-logo-violet.svg"></img></span>olyglotio</div>
        <div className="header-burger-menu">
    <input type="checkbox" id="header-burger-checkbox" className="header-burger-checkbox"/>
    <label for="header-burger-checkbox" className="burger"></label>
    <ul className="burger-menu-list">
        <li><div className="burger-menu-item"><Button sx={{width:"113px",color:"#3A3A3A", border:"1px solid #ECECEC",borderRadius:"14px", fontFamily: "Montserrat", fontSize: "13px", lineHeight: "19.6px", textTransform: "none", backgroundColor:"#FFF",'&:hover':{backgroundColor:"#ebebeb"}}} variant="contained" disableElevation onClick={onSubcriptions}>Subscriptions</Button> </div></li>
        <li><div className="burger-menu-item"><Button sx={{width:"113px",color:"#3A3A3A", border:"1px solid #ECECEC",borderRadius:"14px", fontFamily: "Montserrat", fontSize: "13px", lineHeight: "19.6px", textTransform: "none", backgroundColor:"#FFF",'&:hover':{backgroundColor:"#ebebeb"}}} variant="contained" disableElevation onClick={onExit}>Exit</Button></div></li>
        
    </ul>
</div>
        </div>
        <div class="home-trophy">
          <div
            class={location.pathname === '/generation' ? current : notCurrent}
            onClick={() => navigate('/generation')}>
            Generation
          </div>
          <div
            class={location.pathname === '/' ? current : notCurrent}
            onClick={() => navigate('/')}
          >
            Topics
          </div>
          
        </div>
        {/* <div style={{display:"flex",order:"3"}}>Plan</div> */}
        <Accordion className="username" sx={{ boxShadow:'none' ,'&::before': { display: 'none !important' }, '&.Mui-expanded': { border: '1px solid #D3D3D3',borderRadius:"24px", backgroundColor:"#F9F9F9" }, zIndex:'1'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div>
              <Typography sx={{
fontFamily: "Montserrat",
fontSize: "16px",
fontWeight: "500",
lineHeight: "22.4px",
textAlign: "left"
}}>{info?.name}</Typography>
              <Typography sx={{
fontFamily: "Montserrat",
fontSize: "15px",
fontWeight: "400",
lineHeight: "22.4px",
textAlign: "left"
}}>{info.email}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
          <div className="buttons-details" style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <div className="buttons-details">
              <Button sx={{width:"113px",height:"36px",color:"#3A3A3A", border:"1px solid #ECECEC",borderRadius:"14px", fontFamily: "Montserrat", fontSize: "13px", lineHeight: "19.6px", textTransform: "none", backgroundColor:"#FFF",'&:hover':{backgroundColor:"#ebebeb"}}} variant="contained" disableElevation onClick={onSubcriptions}>Subscriptions</Button>
              {/* <Button sx={{backgroundColor:"#A180C8",'&:hover':{backgroundColor:"#896daa"}}} variant="contained">Settings</Button> */} 
              <Button sx={{width:"113px",height:"36px",color:"#3A3A3A", border:"1px solid #ECECEC",borderRadius:"14px", fontFamily: "Montserrat", fontSize: "13px",display:"flex",justifyContent: "flex-start", lineHeight: "19.6px", textTransform: "none", alignItems: "center", backgroundColor:"#FFF",'&:hover':{backgroundColor:"#ebebeb"}}} variant="contained" disableElevation onClick={onExit}><img src="./images/exit-icon.svg"/>Exit</Button>
            </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </header>
  );
};

export default Header;
